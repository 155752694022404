.MuiDialog-container {
  .MuiPaper-root {
    border-radius: 0;
  }
  .MuiDialogTitle-root {
    padding-bottom: 0;
    font-size: 30px;
    color: #5b5a5a;
    font-weight: bold;
  }
}

.MuiDialogContent-root.dialogContentModal {
  border: 0;
  padding-top: 0;
}

.dialogActionModal {
  padding: 30px 8px;
}

.MuiButton-root.btnModalCancel {
  font-size: 20px;
  color: #5b5a5a;
  text-transform: uppercase;
  width: 100px;
  justify-content: flex-start;
  padding-left: 0;
}

.MuiButton-root.btnModalSubmit {
  color: #001dff;
  text-transform: uppercase;
  font-size: 20px;
  width: 90px;
  justify-content: flex-start;
  padding-left: 0;
}

.textField {
  label {
    font-weight: 600;
  }

  input {
    font-weight: bold;
    font-size: 20px;
    color: #5b5a5a;
  }
}
